<template>
  <v-container fluid>
    <datatable
      :tableHeaders="tableHeaders"
      :tableData="tableData"
      :page.sync="tablePage"
      :pageLimit.sync="tablePageLimit"
      :itemTotal="tableItemTotal"
      :isLoading="tableLoading"
      actionViewRouteLink="OrderDetail"
      @options-update="getList()"
    >
      <template #item.order_status="{ item }">{{ item.order_status | formatOrderStatus }} </template>
    </datatable>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import Datatable from '@/components/Datatable.vue'

export default {
  name: 'Order',
  components: {
    Datatable,
  },
  data: () => ({
    tableLoading: false,
    tablePage: 1,
    tablePageLimit: 10,
    tableData: [],
    tableItemTotal: 0,

    // --- static data
    tableHeaders: [
      { text: '訂單ID', value: 'id' },
      { text: '訂單日期', value: 'create_date' },
      { text: '會員姓名', value: 'username' },
      { text: '會員電話', value: 'phone' },
      { text: '套票', value: 'package_name' },
      { text: '套票價錢', value: 'price', prefix: '$' },
      { text: '狀態', value: 'order_status' },
      { text: '', value: 'actionView', align: 'end' },
    ],
  }),
  methods: {
    ...mapActions(['setAlert']),
    getQueries() {
      const query = this.$route.query
      if (this.$validate.regexNumber(query['p']) && parseInt(query['p']) > 0) {
        this.tablePage = parseInt(query['p'])
      }

      if (this.$validate.regexNumber(query['l']) && parseInt(query['l']) > 0) {
        this.tablePageLimit = parseInt(query['l'])
      }
      this.getList(true)
    },

    _getData() {
      return new Promise(async resolve => {
        try {
          let payload = {
            limit: this.tablePageLimit,
            page: this.tablePage - 1,
            get_user_data: true,
          }

          const user = this.getCurrentUserData()
          const { data, total } = await this.$Fetcher.GetOrders(payload, user.id, user.token)
          this.tableItemTotal = total

          const list = data.map(el => {
            return {
              id: el.id,
              create_date: el.create_date,
              username: el.user_data ? el.user_data.name : '',
              phone: el.user_data ? el.user_data.phone : '',
              package_name: el.coupon_group_data ? el.coupon_group_data.name : '',
              price: el.coupon_group_data ? el.coupon_group_data.price : '',
              order_status: el.status,
            }
          })

          resolve(list)
        } catch (err) {
          this.$common.error(err)
          if (err) {
            this.$store.dispatch('toggleSnack', {
              type: 'error',
              message: err,
            })
          }
          resolve([])
        }
      })
    },

    async getList(pageInit = false) {
      if (!pageInit) {
        const newQuery = {
          p: this.$validate.regexNumber(this.tablePage) && parseInt(this.tablePage) > 1 ? this.tablePage : undefined,
          l:
            this.$validate.regexNumber(this.tablePageLimit) && parseInt(this.tablePageLimit) !== 10
              ? this.tablePageLimit
              : undefined,
        }

        const validNewQuery = this.$common.validNewQuery(this.$route.query, newQuery)
        if (validNewQuery) {
          this.$router.replace({ query: newQuery })
        }
      }

      this.tableLoading = true

      this._getData().then(list => {
        this.tableData = list
        this.tableLoading = false
      })
    },
  },

  async mounted() {
    this.getQueries()
  },
}
</script>
